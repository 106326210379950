.keyboard {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 224px;
	display: flex;
	flex-direction: column;

	background-color: #D0D3D9;
	backdrop-filter: blur(50px);

	z-index: 1001;
	padding-bottom: calc(env(safe-area-inset-bottom) + 42px);
}

.row {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.row.first {
	width: 100%;
}

.row.second {
	width: 100%;
}

.row.third {
	width: 100%;
}

.row.fourth {
	width: 100%;
}

.key {
	position: relative;
	height: 100%;
	width: 100%;
	width: 10%;
}

.key span {
	position: absolute;
	top: 5px;
	right: 3px;
	bottom: 5px;
	left: 3px;
	background-color: white;
	border-radius: 6px;
	padding-bottom: 4px;
	font-size: 24px;
	box-shadow: 0 1px 0px 0px #444;
	display: flex;
	justify-content: center;
	align-items: center;
}

.key.zoom:active span:after {
	width: 100%;
	height: 150%;
	position: absolute;
	content: attr(letter);
	bottom: 0;
	background-color: #333;
	z-index: 1002;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	padding-top: 10px;
	box-shadow: 0 1px 2px 0px rgba(0,0,0,0.5);
	font-size: 24px;
}


.key.darken:active span {
	background-color: #AAB0BB;
}

.key.lighten:active span {
	background-color: #FFF;
}

.key.dark span {
	background-color: #AAB0BB;
}

.key.a {
	width: 15%;
}
.key.a span {
	left: calc(5vw + 3px);
}

.key.l {
	width: 15%;
}
.key.l span {
	right: calc(5vw + 3px);
}


.key.numbers {
	width: 12.5%;
}

.key.numbers span {
	font-size: 15px;
	padding-bottom:1px;
}

.key.emoji {
	width: 12.5%;
}

.key.emoji span {
	font-size: 15px;
	padding-bottom: 0;
}

.key.spacebar {
	width: 50%;
}

.key.spacebar span {
	font-size: 15px;
	padding-bottom: 2px;
}

.key.return {
	width: 25%;
}

.key.return span {
	font-size: 15px;
	background-color: #007AFF;
	padding-bottom: 2px;
}

.key.shift {
	width: 12%;
	margin-right: 3%;
}

.key.shift span {
	background-image: url("../images/shift-key.svg");
	background-size: 20px 20px;
	background-repeat: no-repeat;
 	background-position: center;
}

.key.shift-on {
	width: 12%;
	margin-right: 3%;
}

.key.shift-on span {
	background-image: url("../images/shift-key-on.svg");
	background-size: 20px 20px;
	background-repeat: no-repeat;
 	background-position: center;
 	background-color: white;
}

.key.shift-on:active span {
 	background-color: white;
}

.key.shift-lock {
	width: 12%;
	margin-right: 3%;
}

.key.shift-lock span {
	background-image: url("../images/shift-key-lock.svg");
	background-size: 20px 20px;
	background-repeat: no-repeat;
 	background-position: center;
 	background-color: white;
}

.key.shift-lock:active span {
 	background-color: white;
}

.key.special {
	width: 12%;
	margin-right: 3%;
}

.key.special span {
	font-size: 15px;
	padding-bottom: 0;

}

.key.backspace {
	width: 12%;
	margin-left: 3%;
}

.key.backspace span {
	background-image: url("../images/backspace-key.svg");
	background-size: 20px 20px;
	background-repeat: no-repeat;
 	background-position: center;
}


@media screen and (prefers-color-scheme: dark) {
  .keyboard {
	background-color: rgba(100,100,100,0.4);
  }

  .keyboard span {
	background-color: rgba(255,255,255,0.3);
  }

  .key.dark span {
	background-color: rgba(100,100,100,0.3);
  }

  .key.darken:active span {
	background-color: #333;;
  }
}
