.chat_list_button {
	width: 100%;
	height: 64px;
	position: relative;
	cursor: pointer;
	transition: ease background-color 0.2s;
}

.chat_list_button:active {
	background-color: #222;
}

.chat_list_button .profile_pic {
	height: 44px;
	width: 44px;
	position: absolute;
	top: 10px;
	left: 20px;
	border-radius: 30px;
}

.chat_list_button .name {
	font-size: 14px;
	position: absolute;
	top: 16px;
	left: 78px;
	margin: 0;
	padding: 0;
	color: #333;
}

.chat_list_button .last_message {
	font-size: 12px;
	position: absolute;
	top: 34px;
	left: 78px;
	color: #999;
	margin: 0;
	padding: 0;
}

@media screen and (prefers-color-scheme: dark) {
	.chat_list_button .name {
		color: #dadada;
	}
}

.chat_list_button a {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

