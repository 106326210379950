.message-bubble-container {
	width: 100vw;
	text-align: right;
	height: fit-content;
}

.message-bubble-container.sent {
	text-align: right;
}

.message-bubble-container.sent span {
	text-align: right;
	background-color: #25a;
}


.message-bubble-container.received {
	text-align: left;
}
.message-bubble-container.received span {
	background-color: #333;
}

.message-bubble-container span {
	display: inline-block;
	padding: 9px 14px;
	border-radius: 18px;
	width: fit-content;
	max-width: 70vw;
	height: fit-content;
	background-color: #333;
	margin: 4px 10px;
	font-size: 14px;
  	word-wrap: break-word;
}

