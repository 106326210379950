.header {
	position: fixed;
	top: calc(-315px - calc(env(safe-area-inset-top) * 4.5));
	left: -450vw;
	width: 1000vw;
	height: 700px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: calc(env(safe-area-inset-top) * 10);
	transition: ease background-color 0.2s;
	transform: scale(0.1);
	z-index: 99;
	backdrop-filter: blur(300px);
}

.header.scroll {
	background-color: rgba(100,100,100,0.1);
}

.logo {
	height: 70%;
	max-height: 1200px;
	width: 100%;
	background-image: url("../images/logo.svg");
	background-size: auto 100%;
	background-repeat: no-repeat;

  background-position: center;
	display: block;
}
