.login-view {
	position: fixed;
	top: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 100%;
	width: 100%;

	overflow: hidden;

}

.login-box {
	position: relative;
	height: 400px;
	width: 320px;
	background-color: white;

	backdrop-filter: blur(300px);
	border-radius: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	box-shadow: 0 0 10px 3px rgba(0,0,0,0.07);
}


.login-box-register-button {
	width: 100%;
	height: 60px;
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: red;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #505050;
	background-color: #F7F7F7;
	border-top: 1px solid #E8E8E8;
	font-size: 12px;
}

@keyframes animate_x{
	0%{
		margin-bottom: 0;
		margin-top: 0;
	}
	50% {
		margin-bottom: 20px;
		margin-top: -20px;
	}
	100%{
		margin-bottom: 0;
		margin-top: 0;
	}
}

@keyframes animate_y{
	0%{
		margin-left: 0;
		margin-right: 0;
	}
	50% {
		margin-left: 20px;
		margin-right: -20px;
	}
	100%{
		margin-left: 0;
		margin-right: 0;
	}
}

.animated_logo {
	height: 50px;
	width: 50px;
	position: relative;
}

.animated_logo span {
	position: absolute;
	display: block;
	background-image: linear-gradient(0deg, rgba(21,58,108,0.9), rgba(63,136,234,0.6));
	height: 100%;
	width: 100%;
	border-radius: 1000px;

	box-shadow: 0 6px 10px 1px rgba(0,0,0,0.1);

	animation-name: animated_logo_circle; 
	animation-duration: 0s; 
	animation-iteration-count: infinite; 
	animation-timing-function: ease-in-out; 

}

.animated_logo span:nth-child(1) {
	top: -6px;
	right: -6px;
	animation-duration: 0s; 
}

.animated_logo span:nth-child(2) {
	top: -12px;
	left: -6px;
	animation-duration: 0s; 
}

.brand-text {
	font-size:14px;
	color: black;
}

.form-input {
    position: relative;
    width: 250px;
    height: 42px;
    margin-top: 20px;
}

.form-input input {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 32px);
  height: calc(100% - 2px);
  z-index: 1;
  border-radius: 12px;
  border: 1px solid #B7B7B7;
  padding: 0 16px;
  font-size: 12px;
  background-color: rgba(0,0,0,0);
}

.form-input input:focus {
    outline: none;
}

.form-input label {
  z-index: 2;
  position: absolute;
  top: -7px;
  left: 12px;
  font-size: 11px;
  padding: 1px 4px;
  color: #666;
  font-weight: 700;
  transition: ease all 0.15s;
  border-radius: 6px;
  background-color: white;
}

.form-btn {
	padding: 10px 16px;
	background-color: #3669AD;
	border: none;
	border-radius: 6px;
	color: white;
	font-weight: 700;
	font-size: 12px;
	box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
	cursor: pointer;
    text-decoration: none;
}

.circle_background {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.circle_background span {
	display: block;
	position: absolute;
	background-size: cover;

	animation: animate_x 10s ease infinite, animate_y 8s ease infinite;
}

.circle_background span:nth-child(1) {
	bottom: -50px;
	left: -250px;
	background-color: rgba(145,145,145,0.15);
	border-radius: 250px;
	width: 500px;
	height: 500px;
	filter: blur(5px);

	animation-delay: 0;
}

.circle_background span:nth-child(2) {
	bottom: -40px;
	left: -80px;
	background-image: linear-gradient(0deg, rgba(8,47,98,0.82), rgba(80,126,181,0.33));
	border-radius: 250px;
	width: 150px;
	height: 150px;
	filter: blur(6px);

	animation-delay: 0.5s;
}

.circle_background span:nth-child(3) {
	bottom: 60px;
	right: -5px;
	background-image: linear-gradient(0deg, rgba(55,102,165,0.56), rgba(97,146,214,0.5));
	border-radius: 250px;
	width: 200px;
	height: 200px;
	filter: blur(7px);

	animation-delay: 1s;
}

.circle_background span:nth-child(4) {
	top: 100px;
	left: -170px;
	background-image: linear-gradient(0deg, rgba(32,16,113,0.69), rgba(57,135,239,0.53));
	border-radius: 250px;
	width: 200px;
	height: 200px;
	filter: blur(8px);

	animation-delay: 1.5s;
}

.circle_background span:nth-child(5) {
	top: -100px;
	right: -200px;
	background-color: rgba(145,145,145,0.15);
	border-radius: 250px;
	width: 350px;
	height: 350px;
	filter: blur(10px);

	animation-delay: 2s;
}

.circle_background span:nth-child(6) {
	top: 80px;
	right: 100px;
	background-image: linear-gradient(0deg, rgba(67,138,229,0.35), rgba(65,122,247,0.52));
	border-radius: 250px;
	width: 100px;
	height: 100px;
	filter: blur(3px);

	animation-delay: 2.5s;
}

@media screen and (prefers-color-scheme: dark) {
	.brand-text {
		color: white;
	}

	.login-box-register-button {
		background-color: #111;
		color: #DBDBDB;
		border-top: 1px solid #2a2a2a;
	}

	.login-box {
		background-color: #0a0a0a;
	}
	
	.form-btn {
		background-color: #294D7E;
	}

	.form-input label {
		background-color: #0a0a0a;
		color: #BEBEBE;
	}

	.form-input input {
	    border: 1px solid #2a2a2a;
	    color: white;
	}
}