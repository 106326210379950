.chat-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(60px + env(safe-area-inset-top));
	border-bottom: 1px solid #222;

	background-color: rgba(20,20,20,0.7);
	backdrop-filter: blur(50px);

	z-index: 1000;

}

.chat-header .profile_pic {
	height: 40px;
	width: 40px;
	position: absolute;
	bottom: 9px;
	left: 26px;
	border-radius: 30px;
}

.chat-header .name {
	font-size: 14px;
	position: absolute;
	bottom: 28px;
	left: 78px;
	margin: 0;
	padding: 0;
	color: #ddd;
}

.chat-header .online_status {
	font-size: 10px;
	position: absolute;
	bottom: 14px;
	left: 78px;
	color: #999;
	margin: 0;
	padding: 0;
}

.chat-header .back-arrow {

	position: absolute;
	bottom: 14px;
	right: 22px;
	width: 30px;
	height: 30px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 25px;

	transition: ease background-color 0.2s;
}

.chat-header .back-arrow:active {
	background-color: rgba(100,100,100,0.2);
}

.chat-header .back-arrow span {
	display: block;

    border: solid #ccc;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-right: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); 
}