#chat-root {
	position: fixed;
	top: 0;
	left: -100vw;
	z-index: 999;
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	overflow: hidden;
	background-color: #111;
}

.chat-container {
	position: absolute;
	top: 0;
	left: 0;
	height: calc(100% - calc(calc(70px + env(safe-area-inset-top)) + calc(50px + env(safe-area-inset-bottom))));
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-top: calc(70px + env(safe-area-inset-top));
	padding-bottom: calc(50px + env(safe-area-inset-bottom));
	-webkit-overflow-scrolling: touch;

}

.chat-container.keyboard-open {
	padding-bottom: calc(272px + env(safe-area-inset-bottom));
	height: calc(100% - calc(calc(70px + env(safe-area-inset-top)) + calc(326px + env(safe-area-inset-bottom))));
}