.bottom-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 66px;
	width: 100vw;

	display: flex;
	padding-bottom: env(safe-area-inset-bottom);
	justify-content: center;

	z-index: 99;
}

.bottom-nav-button {
	position: relative;
	height: 100%;
	flex-grow: 1;
	max-width: 90px;
	display: flex;
	justify-content: center;
	align-items: center;

	transition: ease all 0.4s;

	-webkit-tap-highlight-color: rgba(0,0,0,0); 
}

.bottom-nav-border {
	position: absolute;
	top: -1px;

	height: 244px;
	width: 6545px;

	transition: ease all 0.3s;
	transform: translateX(calc(50vw + 70px));

	display: block;
	background-image: url("../images/bottom-nav.svg");
}

@media screen and (prefers-color-scheme: dark) {
	.bottom-nav-border {
		background-image: url("../images/bottom-nav_dark.svg");
	}
}

.bottom-nav-selected-circle {
	display: block;
	height: 60px;
	width: 60px;
	border-radius: 30px;
	background: linear-gradient(140deg, #4E5DaE, #20467C);

	position: absolute;
	top: -24px;
	transition: ease all 0.2s;
	transform: translateX(calc(50vw + 30px));
}

.bottom-nav-icon {
	z-index: 2;
	filter: brightness(0.6);
	transition: ease filter 0.2s;
}

.bottom-nav-button.active {
	transform: translateY(-26px);
}

.bottom-nav-button.active .bottom-nav-icon {
	filter: brightness(1.0);
}

.glyph {
	display: block;
	background-size: cover;

}

.glyph.ic-home {
	background-image: url("../images/ic-home.svg");
	height: 18px;
	width: 20px;
}

.glyph.ic-chat {
	background-image: url("../images/ic-chat.svg");
	height: 18px;
	width: 22px;
}

.glyph.ic-friends {
	background-image: url("../images/ic-friends.svg");
	height: 18px;
	width: 23px;
}

