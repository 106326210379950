.message-sender {
	position: absolute;
	bottom: 298px;
	left: 0;
	width: 100%;
	height: calc(50px + env(safe-area-inset-bottom));
	border-top: 1px solid #333;

	
	background-color: rgba(100,100,100,0.4);
	backdrop-filter: blur(50px);
	z-index: 1000;

}

.message-sender.open {

	bottom: calc(266px + env(safe-area-inset-bottom));
	height: 50px;
}

.message-sender input {
	background: rgba(100,100,100,0.3);
	border-radius: 20px;
	border: none;

	position: absolute;
	top: 6px;
	left: 12px;
	bottom: calc(6px + env(safe-area-inset-bottom));
	right: 12px;
	padding: 0 16px;
	font-size: 14px;

	color: white;

}

.message-sender.open input {
	bottom: 6px;
}

::-webkit-input-placeholder { 
  color: #888
}

.message-sender .send {
	display: block;

	background-color: #25a;
	color: white;
	border-radius: 20px;
	border: none;

	position: absolute;
	top: 10px;
	right: -80px;
	bottom: calc(10px + env(safe-area-inset-bottom));

	padding: 0 16px;
	font-size: 14px;

}
