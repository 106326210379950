body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-top: calc(env(safe-area-inset-top));
  padding-bottom: calc(env(safe-area-inset-bottom));
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  overflow-x: hidden;
}

html, body {
  background-color: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

}


@media screen and (prefers-color-scheme: dark) {
  html, body {
    background-color: #111;
    color: white;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

