.seperator {
	width: calc(100% - 0px);
	height: 0.5px;
	background-color: #eee;
	display: block;
	position: absolute;
	left: 0;
}

@media screen and (prefers-color-scheme: dark) {
	.seperator {
		background-color: #222;
	}
}